import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

// Global atoms
export const token = atomWithStorage<string>('WebsiteTK','');
// 登录状态 根据token判断
export const isLoginAtom = atom((get) => {
    return !!get(token);
})

// User atoms
export const userInfo = atomWithStorage<User|''>('WebsiteUserInfo', '');
